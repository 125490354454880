'use strict';

/**
 * @ngdoc function
 * @name architizerWebsiteApp.controller:SourceController
 * @description
 * # SourceController
 * Controller of the architizerWebsiteApp
 */
angular
  .module('architizer.app')
  .controller('SettingsFirmCompanyController', [
    'firm',
    '$scope',
    '$http',
    '$state',
    'GlobalView',
    'PopupManager',
    'Firm',
    'FlashManager',
    function (firm, $scope, $http, $state, globalView, popupManager, Firm, flashManager) {

    	globalView.title = 'Architizer - Settings';
    	globalView.currentSecondaryItem = '';

    	$scope.viewbag = {
        firm: firm,
        models: {
          firm: {
            name: '',
            website: '',
            allow_projects_award_badges: true
          },
          invite: {
            permissions: {
              admin: true,
              notifications: true,
              respond: true,
              manage: true
            }
          }
        },
        elementConfig: {
          firm: {}
       	},
        working: false
      };

      var init = function() {

        _populateWithFirm(firm);
      };

      var _populateWithFirm = function (firm) {

        // Split into models for the firm form
        $scope.viewbag.models.firm.name = firm.name;
        $scope.viewbag.models.firm.website = firm.website_url;
        $scope.viewbag.models.firm.allow_projects_award_badges = firm.allow_projects_award_badges;
      };

      // When the brand details are successfully saved
      var onSaveSuccess = function (f) {

        // Reset the form
        $scope.firmInfo.$setPristine();
        $scope.firmInfo.$setUntouched();

        // Update the settings header
        firm.name = f.name;

        // Update loading status
        $scope.viewbag.working = false;

        // Present a flash
        flashManager.push({
          message: 'Your firm information was updated',
          type: 'success'
        });
      };

      // When an error occurs while saving a brand
      var onSaveError = function () {

        // Update loading status
        $scope.viewbag.working = false;
      };

      // When the save button is clicked
      $scope.onFirmInfoSubmit = function () {

        if ($scope.firmInfo.$invalid) {

          return;
        }

        // Indicate loading status
        $scope.viewbag.working = true;

        var firm = new Firm({
          id: $scope.viewbag.firm.id,
          name: $scope.viewbag.models.firm.name,
          website_url: $scope.viewbag.models.firm.website,
          allow_projects_award_badges: $scope.viewbag.models.firm.allow_projects_award_badges
        });

        firm.$update(onSaveSuccess, onSaveError);
      };

      // Kick things off
      init();

    }]);
